.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dt-button-collection div {
    margin-top: 1.5em;
    background: #ffffff;
    padding: 1.5em 1em;
    z-index: 99;
    position: relative;
    border-radius: 15px;
    box-shadow: 1px 2px 4px;
}

.dt-button-collection div button {
    border: 1px solid #0d6efd;
    border-radius: 5px;
    background: #fff;
    color: #0d6efd;
    font-weight: normal;
    padding: 5px 10px;
    font-size: 14px;
}

.dt-button-collection div button:hover {
    border: 1px solid #d61103;
    border-radius: 5px;
    background: #d61103;
    color: #fff;
    font-weight: normal;
    padding: 5px 10px;
    font-size: 14px;
}